<template>
    <div v-if="isShow && match.is_show">
        
        <!-- <span class="text-sm" v-if="!match.start_time">{{ betCloseTime() }}</span> -->
        <!-- betting team card -->
        <div v-if="type !== 'history'" class="match-container mb-2 px-1 py-2" :class="match.is_big ? 'big-match-design' : ''">
            <span class="text-md mt-1 text-dark pl-2" v-if="match.start_time"><span class="text-danger text-lg" v-if="match.is_big && $route.path !== '/maung-fb'"> * * </span> <span class="font-weight-bolder text-md" v-if="match.league">{{ match.league }} </span>
            <span class="text-danger" v-if="match.is_big && $route.path !== '/maung-fb'">
                            ( {{
                                $store.state.language === "en"
                                    ? "BIG MATCH"
                                    : "ပွဲကြီး"
                            }} )</span>
            ( {{ betTime(match.start_time) }} )</span><br>
            <div
                class="upper-card w-100 d-flex justify-content-around"
                style="height: 40px;"
            >
                <div
                    class="upper-left-card d-flex"
                    style="width: 50%; border-radius: 3px; background: #009646; color: white;"
                    :class="
                        ((betMatch.selected_team === match.first_team) && (!isGoalPor && !isSoneMa))
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.first_team, null, match.first_team_id)
                    "
                >
                    <span class="my-auto mx-auto"
                        >
                        <b>
                            {{
                                $store.state.language === "en"
                                    ? match.first_team_en
                                    : match.first_team
                            }}</b
                        >
                        </span
                    >
                </div>

                <!-- <div
                    class="upper-left-card d-flex"
                    style="width: 20%; border-radius: 10px; background: #0084C7;"
                >
                    <span class="my-auto mx-auto">
                        <b>
                            {{ $store.state.language === "en" ? "Draw" : "သရေ" }}
                        </b>
                    </span>
                </div> -->

                <div
                    class="upper-right-card d-flex"
                    style="width: 50%; border-radius: 3px; background: #009646; color: white;"
                    :class="
                        ((betMatch.selected_team === match.second_team) && (!isGoalPor && !isSoneMa))
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.second_team, null, match.second_team_id)
                    "
                >
                    <span class="text-center my-auto mx-auto"
                        >
                        <b
                            >{{
                                $store.state.language === "en"
                                    ? match.second_team_en
                                    : match.second_team
                            }}</b
                        >
                        </span
                    >

                </div>
            </div>
            

            <!-- SONE MA  -->
            <div
                class="upper-card d-flex flex-wrap px-0 mx-0 justify-content-center flex-shrink-1"
                style="height: auto; width: 100%;"
            >
                <div
                    class="upper-left-card d-flex p-0 m-1"
                    style="border-radius: 3px; height: 50px; width: 30%;"
                    v-for="(correctScore, index) in match.correctScores"
                    :key="index"
                    :class="betMatch.correct_score === correctScore.score ? 'active': null"
                    @click="checkTeam(correctScore.score, correctScore.odds)"
                >
                    <span class="my-auto mx-auto text-center pt-2"
                        >
                        <p :class="betMatch.correct_score === correctScore.score ? 'badge-dark text-white': 'badge-danger'" class="badge my-auto" style="font-size: 14px; width: 50px; padding-top:2px; padding-bottom:2px;">
                            <b>
                                {{
                                    correctScore.odds
                                }}
                            </b>
                        </p>
                         <br>
                        <span class="pt-2"> <b  style="letter-spacing: 0.3rem;">{{correctScore.score}}</b></span>
                        </span
                    >
                </div>
            </div>
            <!-- END SONE MA  -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
// Import the Luxon library
import { DateTime } from 'luxon';

export default {
  //   props: ['match', 'type'],
  props: {
    match: Object,
    history: Object,
    type: String,
    onMyEvent: Function,
    isKyayHistory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      targetTimeZone: 'Asia/Yangon',
      currentTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      interval: null,
      isShow: true,
      isLast: false,
      betMatch: {},
      isSelect: false,
    };
  },
  computed: {
    ...mapGetters(['getFBOrder']),
  },
  methods: {
    betTime(time) {
      return moment(new Date(time)).format('lll');
    },
    ...mapMutations(['setFBMatches', 'updateFBMatches', 'removeFBMatch', 'setWholeFBMatches']),
    checkTeam(selectedScore, selectedOdds) {
      const matchIndex = this.getFBOrder.fb_matches.findIndex(
        (match) => match.id === this.match.id,
      );

      if (selectedScore === this.betMatch.correct_score) {
        this.betMatch = {};
        this.removeFBMatch(matchIndex);
        return false;
      }

        this.setWholeFBMatches([])
        this.$emit('removeMatches')
    
      this.betMatch = {};
      this.betMatch = this.match;
      this.betMatch.selected_score = null;
      this.betMatch.selected_kyay = null;
      this.betMatch.goal_up_down = null;
      this.betMatch.sone_ma = null;

      this.betMatch.correct_score = selectedScore;
      this.betMatch.correct_odds = selectedOdds;
      console.log('hello')

      if (matchIndex === -1) this.setFBMatches(this.betMatch);
      else {
        this.updateFBMatches({
          index: matchIndex,
          value: this.betMatch,
        });
      }
      return true;
    },
    checkFBMatchTime() {
      const matchTime = new Date(this.match.start_time);
      const nowTime = new Date();
      this.isShow = matchTime > nowTime;
      if(!this.isShow) {
        const index = this.getFBOrder.fb_matches.findIndex((match) => match.id === this.match.id)
        if(index !== -1) {
            let newDatas = JSON.parse(JSON.stringify(this.getFBOrder.fb_matches));
            newDatas.splice(index, 1);
            this.setWholeFBMatches(newDatas);
        }
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  mounted() {
    // this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.onMyEvent(() => {
      this.betMatch = {};
    });
    if (this.type !== 'history') {
      this.checkFBMatchTime();
      this.interval = setInterval(() => this.checkFBMatchTime(), 3000);
    }
  },
};
</script>
<style scoped>
.big-match-design {
  /* border-width: 3px !important;
  border-style: solid !important;
  border-radius: 5px !important; */
  background: rgb(177, 176, 176) !important;
  border: none !important;
  /* color: white !important; */
}
.goal-por {
    text-align: center;
}
.goal-por,
.goal-por {
    background: #E0E7F1;
    color: black;
    border-radius: 3px;
    margin-right: 100px;
    padding-bottom: 5px;
    /* */
    width: 100%;
    height: 34px !important;
    font-size: 12px !important;
}
.goal-por:active,
.goal-por.active {
    background: #009646 !important;
    color: white !important;
}
.kyay-left,
.kyay-right {
    width: calc(50% - 30px);
    margin: 0px;
}
.kyay-left  {
    margin-right: 5px;
}
.kyay-right  {
    margin-left: 5px;
}
.team-image {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    margin: 3px;
}
.match-container {
    /* border: 1px solid rgb(191, 190, 190); */
    border-radius: 3px;
    background: #F6F6F6 !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
/* .lower-card {
    border-top: 1px solid rgb(220, 217, 217);
} */
hr {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.upper-right-card,
.upper-left-card,
.upper-right-card,
.upper-left-card {
    background: #E0E7F1;
    color: black;
    border-radius: 3px;
    margin-top: 3px;
    /* margin-left: 3px;
    margin-right: 3px; */
    margin-bottom: 3px;
    /* */
}

.upper-right-card{
    margin-left: 3px;
    margin-right: 3px;
}
.upper-left-card{
    margin-left: 3px;
    margin-right: 3px;
}

.upper-right-card:active,
.upper-left-card:active,
.upper-right-card.active,
.upper-left-card.active {
    background: #009646 !important;
    color: white;
    border-radius: 3px;
    margin-top: 3px;
    /* margin-left: 3px;
    margin-right: 3px; */
    margin-bottom: 3px;
}
</style>
